import React from 'react'
import { createUseStyles } from 'react-jss'
import { sessionStore } from '../../utils/StorageUtils'

const styles = createUseStyles((theme) => ({
  links: {
    [theme.queries.S]: { display: 'none' },
    display: 'inline-block',
    padding: (props) => props.padding,
    margin: 0,
    textAlign: (props) => props.alignitems,
    listStyleType: 'none',
    '& li': {
      display: 'inline-block',
      margin: '0 20px',

      [`@media (max-width: ${theme.breakpoints.S})`]: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 10,
      },
    },
    '& a': {
      color: theme.colors.darkGrey,
      fontSize: 13,
      letterSpacing: 1.08,
      fontWeight: 400,
      textTransform: 'uppercase',
      position: 'relative',
      display: 'inline-block',
      '&:after': {
        content: "''",
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: -10,
        margin: 'auto',
        background: theme.colors.primaryGreen,
        height: 2,
        width: '80%',
        display: 'none',
      },
      '&:hover': {
        color: theme.colors.primaryGreen,
        textDecoration: 'none',
        '&:after': {
          display: 'block',
        },
      },
    },
  },
}))

type Items = {
  title: string
  href: string
}

type Props = {
  items: Items[]
  partnerId?: string
  alignItems?: String
  padding?: String
}

const NavbarMenu: React.FC<Props> = ({ items, partnerId, ...props }) => {
  const classes = styles(props)

  if (partnerId) {
    return null
  }

  return (
    <ul className={classes.links}>
      {items.map((item) => (
        <li key={item.title}>
          <a href={item.href}>{item.title}</a>
        </li>
      ))}
    </ul>
  )
}

NavbarMenu.defaultProps = {
  alignItems: 'center',
  padding: '0',
}

export default NavbarMenu
