import React, { useEffect, useState } from "react"

import { createUseStyles } from "react-jss"
import { usePopper } from "react-popper"
import {sessionStore} from "../../utils/StorageUtils";

const styles = createUseStyles(theme => ({
  listWrapper: {
    background: "white",
    padding: 20,
    borderRadius: 10,
    boxShadow: "0 12px 20px 0 rgba(166,171,189,0.4)",
    visibility: isOpen => (isOpen ? "visible" : "hidden"),
    zIndex: 100000,
  },
  list: {
    padding: 0,
    margin: 0,
    listStyleType: "none",
    color: theme.colors.darkGrey,
    fontWeight: 600,
    fontSize: 12,
    textTransform: "uppercase",
    position: "relative",
    "& li": {
      padding: "4px 0",
    },
    "& a": {
      color: theme.colors.darkGrey,
      fontWeight: 600,
      fontSize: 12,
      textTransform: "uppercase",
      position: "relative",
      "&:hover": {
        textDecoration: "none",
        color: theme.colors.primaryGreen,
        "& i:before": {
          color: theme.colors.primaryGreen,
        },
      },
      "& i": {
        color: theme.colors.darkGrey,
        fontSize: 15,
        marginRight: 5,
        position: "relative",
        top: 2,
      },
    },
  },
  arrow: {
    position: "absolute",
    width: 10,
    height: 10,
    "&:before": {
      content: `""`,
      position: "absolute",
      left: 0,
      transform: "rotate(45deg)",
      width: 10,
      height: 10,
      background: "white",
      top: -25,
    },
  },
  button: {
    marginLeft: 25,
    height: 22,
    width: 22,
    background: "white",
    border: isOpen =>
      `1px solid ${
        isOpen ? theme.colors.primaryGreen : theme.colors.lightGrey
      }`,
    borderRadius: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& i": {
      transform: isOpen => (isOpen ? "rotate(0deg)" : "rotate(180deg)"),
      fontSize: 9,
      "&:before": {
        color: isOpen =>
          isOpen ? theme.colors.primaryGreen : theme.colors.primaryGrey,
      },
    },
  },
}))

type Items = {
  title: string
  href: string
  icon?: String
}

type DropdownProps = {
  items: Items[]
  openOnNewTab: boolean
}

const Dropdown: React.FC<DropdownProps> = ({ items, openOnNewTab }) => {
  const [isOpen, toggleOpen] = useState<boolean>(false)
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const [arrowElement, setArrowElement] = useState(null)
  const { styles: popperStyles, attributes, forceUpdate } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: "bottom-end",
      modifiers: [
        { name: "offset", options: { offset: [10, 10] } },
        { name: "arrow", options: { element: arrowElement } },
      ],
    }
  )
  const classes = styles(isOpen)
  const partnerId = sessionStore?.getItem("partnerId");

  if (partnerId) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      <button
        className={classes.button}
        onClick={(event: React.MouseEvent<HTMLElement>) => toggleOpen(!isOpen)}
        ref={setReferenceElement}
      >
        <i className="icon-arrow-top" />
      </button>
      <div
        ref={setPopperElement}
        style={popperStyles.popper}
        {...attributes.popper}
        className={classes.listWrapper}
      >
        <div
          className={classes.arrow}
          ref={setArrowElement}
          style={popperStyles.arrow}
        />
        <ul className={classes.list}>
          {items.map(item => (
            <li key={item.title}>
              <a href={item.href} target={openOnNewTab ? "_blank" : ""}>
                {item.icon ? <i className={`icon-${item.icon}`} /> : null}{" "}
                {item.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Dropdown
