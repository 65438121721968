import { GET_CUSTOMER_SETS } from "apollo/static-queries/configuratorQueries"
import { Link } from "gatsby"
import React from "react"
import { createUseStyles } from "react-jss"
import { getCartCountTotal } from "apollo/reactive-variables/configuratorVariables"
import { useQuery } from "@apollo/client"

const styles = createUseStyles(theme => {
  return {
    cart: {
      display: "inline-block",
      position: "relative",
      width: 36,
      height: 36,
      lineHeight: "36px",
      textAlign: "center",
      borderRadius: "100%",
      border: `1px solid ${theme.colors.primaryGreen}`,
      marginLeft: 10,
      "&:hover": {
        textDecoration: "none",
      },
      "& i": {
        fontSize: 17,
        "&:before": {
          color: theme.colors.primaryGreen,
        },
      },
    },
    count: {
      height: 16.5,
      width: 16.5,
      background: theme.colors.darkGrey,
      color: "white",
      position: "absolute",
      fontSize: 11,
      lineHeight: "18px",
      top: -5,
      right: -5,
      borderRadius: "100%",
    },
  }
})

type CartIconProps = {}

const CartIcon: React.ComponentType<CartIconProps> = (props: CartIconProps) => {
  const classes = styles()

  const { data } = useQuery(GET_CUSTOMER_SETS)
  const CUSTOMER_SETS = data.customerSets
  const ACTIVE_CUSTOMER_SET = data.activeCustomerSet

  const [itemsCount, setItemsCount] = React.useState(getCartCountTotal())
  React.useEffect(() => {
    setItemsCount(getCartCountTotal())
  }, [ACTIVE_CUSTOMER_SET.cart])

  return (
    <Link className={classes.cart} to="/konfigurator-kosik/">
      <i className="icon-cart" />
      {itemsCount > 0 && <span className={classes.count}>{itemsCount}</span>}
    </Link>
  )
}

export default CartIcon
