import { graphql, useStaticQuery } from "gatsby"

import { gql } from "@apollo/client"

export const GET_IS_CUSTOMER_SET_HINT_VISIBLE = gql`
  query GetIsCustomerSetHintVisible {
    isCustomerSetHintVisible @client
  }
`

export const GET_CUSTOMER_SETS = gql`
  query GetCustomerSets {
    customerSets @client
    activeCustomerSet @client
    isCustomerSetHintVisible @client
  }
`
