import { Col, Container, Row } from 'react-bootstrap'

import CartIcon from '../CartIcon'
import Dropdown from '../Dropdown'
import { Link } from 'gatsby'
import Logo from '../../images/logo.svg'
import NavbarMenu from '../NavbarMenu'
import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useLocation } from '@reach/router'

type links = {
  title: string
  href: string
  icon?: string
}

export const headerLinks: links[] = [
  { title: 'Prodejny', href: '/prodejny' },
  { title: 'Najít elektrikáře', href: '/elektrikari' },
  { title: 'Ke stažení', href: '/ke-stazeni' },
  { title: 'Blog', href: '/blog' },
  { title: 'Kontakt', href: '/kontakty' },
]

export const headerMoreLinks: links[] = [
  {
    icon: 'schneider',
    title: 'Schneider Electric',
    href: 'https://www.se.com/cz',
  },
  {
    icon: 'smart-solutions',
    title: 'Inteligentní bydlení',
    href: 'https://www.inteligentni-bydleni.cz',
  },
  { icon: 'schneider', title: 'Thorsman', href: 'https://www.thorsman.cz' },
  { icon: 'schneider', title: 'Esklady', href: 'https://www.esklady.cz' },
]

const styles = createUseStyles((theme) => ({
  wrapper: {
    padding: '20px 0',
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 1000,
  },
  logo: {
    height: 26,
    width: 'auto',
    maxWidth: '100%',
  },
  boxArrowWrapper: {
    zIndex: 10,
    position: 'absolute',
    top: '-24px',
    right: '20px',
    display: 'flex',
    justifyContent: 'center',
  },

  boxArrow: {
    border: '10px solid rgba(0,0,0,0)',
    borderBottomColor: 'white',
  },
  logoWrapper: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  menuActionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  menuIconWrapper: {
    position: 'relative',
    height: '20px',
    display: 'none',
    marginRight: 20,
    bottom: '2px',
    [theme.queries.S]: {
      display: 'inline-block',
    },
  },

  menuIcon: {
    position: 'relative',
    top: 3,
    '&:before': {
      fontSize: '20px',
    },
    '&:hover': {
      cursor: 'pointer',
      textShadow: '1px 2px 3px rgba(0,0,0, .2)',
    },
  },
  dropdown: {
    position: 'relative',
    display: 'inline-block',
    fontSize: '12px',
    fontWeight: '600',
    letterSpacing: '1px',
    textTransform: 'uppercase',

    '&-content': {
      width: '70vw',
      position: 'absolute',
      top: '40px',
      right: '-20px',
      zIndex: 1000,
      padding: '14px 22px',

      boxShadow: '0 12px 20px rgba(166, 171, 189, 0.4)',
      borderRadius: '10px',
      border: '1px solid #efefef',
      backgroundColor: '#ffffff',
      backgroundImage:
        'linear-gradient(-42deg, rgba(0, 0, 0, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%)',

      '& a': {
        color: theme.colors.darkGrey,
      },

      // rows
      '&>*': {
        minHeight: '30px',
        display: 'flex',
        alignItems: 'center',

        '& i:before': {
          fontSize: '15px',
          marginRight: '10px',
        },
      },
      '&>*:hover': {
        cursor: 'pointer',
        textShadow: '1px 2px 3px rgba(0,0,0, .2)',
        textDecoration: 'none',
      },
    },
  },
}))

const Header: React.FC = () => {
  const classes = styles()
  const [showMenuDropdown, setShowMenuDropdown] = useState(false)
  const location = useLocation()
  const [partnerId, setPartnerId] = useState(
    new URLSearchParams(location.search).get('partnerId'),
  )

  useEffect(() => {
    if (
      new URLSearchParams(location.search).get('partnerId') ||
      sessionStorage.getItem('partnerId')
    ) {
      setPartnerId(
        new URLSearchParams(location.search).get('partnerId') ??
          sessionStorage.getItem('partnerId'),
      )
    }
  }, [location])

  return (
    <header className={classes.wrapper}>
      <Container>
        <Row>
          <Col md={4} xs={8}>
            <div className={classes.logoWrapper}>
              <Link to={partnerId ? `/?partnerId=${partnerId}` : '/'}>
                <img className={classes.logo} src={Logo} />
              </Link>
              {!partnerId && (
                <Dropdown openOnNewTab={true} items={headerMoreLinks} />
              )}
            </div>
          </Col>
          <Col md={8} xs={4}>
            <div className={`${classes.menuActionsWrapper}`}>
              {!partnerId && (
                <div className={`${classes.menuIconWrapper}`}>
                  <i
                    className={`icon-menu ${classes.menuIcon}`}
                    onClick={() => setShowMenuDropdown(!showMenuDropdown)}
                  />
                  {/* DROPDOWN */}
                  <div
                    className={`${classes.dropdown}-content`}
                    style={{
                      display: `${showMenuDropdown ? 'block' : 'none'}`,
                    }}
                  >
                    <div className={classes.boxArrowWrapper}>
                      <div className={classes.boxArrow} />
                    </div>
                    {headerLinks.map((headerLink, index) => (
                      <Link to={headerLink.href} key={index}>
                        {headerLink.title}
                      </Link>
                    ))}
                  </div>
                </div>
              )}
              <NavbarMenu items={headerLinks} partnerId={partnerId} />
              <CartIcon />
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  )
}

export default Header
