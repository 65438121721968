import { Col, Container, Row } from 'react-bootstrap'

import NavbarMenu from '../NavbarMenu'
import React from 'react'
import { createUseStyles } from 'react-jss'
import facebookLogo from '../../images/icons/fb.svg'
import facebookLogoHover from '../../images/icons/FB_active.svg'
import { headerMoreLinks } from '../Header'
import instagramLogo from '../../images/icons/insta.svg'
import instagramLogoHover from '../../images/icons/insta_active.svg'
import logo from '../../images/logo.svg'
import youtubeLogo from '../../images/icons/youtube.svg'
import youtubeLogoHover from '../../images/icons/youtube-active.svg'
import { useTranslation } from 'react-i18next'
import { sessionStore } from '../../utils/StorageUtils'

type links = {
  title: string
  href: string
}

const footerLinks: links[] = [
  { title: 'Prodejny', href: '/prodejny' },
  { title: 'Najít elektrikáře', href: '/elektrikari' },
  { title: 'Ke stažení', href: '/ke-stazeni' },
  { title: 'Blog', href: '/blog' },
  { title: 'Kontakt', href: '/kontakty' },
]

const styles = createUseStyles((theme) => ({
  wrapper: {
    paddingTop: 150,
    paddingBottom: 45,
    [theme.queries.XS]: {
      paddingTop: 50,
    },
  },
  logo: {
    height: 'auto',
    width: 'auto',
    maxWidth: '100%',
    textAlign: 'center',
    marginBottom: 40,
  },
  divider: {
    display: 'inline-block',
    width: 1,
    height: '13px',
    background: theme.colors.primaryGrey,
    margin: '0 10px',
    opacity: 0.59,
    position: 'relative',
    top: 2,
    [theme.queries.XS]: {
      display: 'none',
    },
  },
  more: {
    margin: '25px 0',
    padding: '10px 0',
    borderWidth: '1px 0 1px 0',
    borderColor: 'rgba(119, 123, 145, 0.3)',
    borderStyle: 'solid',
    '& span': {
      color: theme.colors.primaryGrey,
      fontSize: 12,
      letterSpacing: '0.92px',
      display: 'inline-block',
      marginRight: 30,
    },
  },
  grey: {
    color: theme.colors.primaryGrey,
    fontSize: 12,
    letterSpacing: 0.9,
    opacity: 0.59,
  },
  socialIcon: {
    height: 30,
    display: 'inline-block',
    width: 30,
    backgroundRepeat: 'no-repeat',
    boxShadow: '-2px -2px 3px 0 #FFFFFF, 2px 2px 3px 0 #A6ABBD',
    backgroundSize: '60% 60%',
    backgroundPosition: 'center center',
    borderRadius: 5.9,
    '&:hover': {
      boxShadow: 'inset -2px -2px 3px 0 #FAFBFF, inset 2px 2px 3px 0 #A6ABBD',
      cursor: 'pointer',
    },
    '&.instagram': {
      backgroundImage: `url(${instagramLogo})`,
      '&:hover': {
        backgroundImage: `url(${instagramLogoHover})`,
      },
    },
    '&.facebook': {
      margin: '0 35px',
      backgroundImage: `url(${facebookLogo})`,
      '&:hover': {
        backgroundImage: `url(${facebookLogoHover})`,
      },
    },
    '&.youtube': {
      backgroundImage: `url(${youtubeLogo})`,
      '&:hover': {
        backgroundImage: `url(${youtubeLogoHover})`,
      },
    },
  },
  moreItemsList: {
    display: 'inline-block',
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    [theme.queries.XS]: {
      marginBottom: 15,
      width: '100%',
    },
    '&:nth-of-type(2)': {
      float: 'right',
    },
    '& li': {
      display: 'inline-block',
      marginRight: 30,
      [theme.queries.XS]: {
        display: 'list-item',
      },
      '&:last-of-type': {
        marginRight: 0,
      },
      '& a': {
        color: theme.colors.darkGrey,
        fontWeight: 600,
        fontSize: 12,
        textTransform: 'uppercase',
        position: 'relative',
        '&:hover': {
          textDecoration: 'none',
          color: theme.colors.primaryGreen,
          '& i:before': {
            color: theme.colors.primaryGreen,
          },
        },
        '& i': {
          color: theme.colors.darkGrey,
          fontSize: 15,
          marginRight: 7,
          position: 'relative',
          top: 2,
        },
      },
    },
  },

  direction: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '&--row': {
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
  },
  bottom: {
    [theme.queries.XS]: {
      '& div': {
        width: '100%',
        textAlign: 'center',
      },
      '& a': {
        display: 'inline-block',
        width: '100%',
      },
    },
  },
}))

type DataProps = {
  showInRow?: boolean
}

const Footer: React.FC<DataProps> = ({ showInRow }) => {
  const classes = styles()
  const partnerId = sessionStore?.getItem('partnerId')
  const { t } = useTranslation()

  return (
    <footer className={classes.wrapper}>
      <Container>
        <section
          className={`${classes.direction} ${
            showInRow ? `${classes.direction}--row` : ''
          }`}
        >
          <div className={classes.logo}>
            <img src={logo} alt="Schneider Electric - Vypínač" />
          </div>
          {!partnerId && <NavbarMenu padding="35px 0" items={footerLinks} />}
          {!partnerId && (
            <div>
              <a
                target="_blank"
                href="https://www.instagram.com/vypinac.cz/?hl=cs"
                className={`${classes.socialIcon} instagram`}
              ></a>
              <a
                target="_blank"
                href="https://www.facebook.com/vypinac.cz"
                className={`${classes.socialIcon} facebook`}
              ></a>
              <a
                target="_blank"
                href="https://www.youtube.com/user/SchneiderElectricCZ"
                className={`${classes.socialIcon} youtube`}
              ></a>
            </div>
          )}
        </section>

        {!partnerId && (
          <Row>
            <Col>
              <div className={classes.more}>
                <Row className="justify-content-between">
                  <Col xs={12} md="auto">
                    <span>{t('common:MORE_FROM_SCHNEIDER_ELECTRIC')}</span>
                    <ul className={classes.moreItemsList}>
                      {headerMoreLinks.map((item) => (
                        <li key={item.title}>
                          <a href={item.href} target="_blank">
                            <i className={`icon-${item.icon}`}></i>
                            {item.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </Col>
                  <Col xs={12} md="auto" className="float-right">
                    <ul className={classes.moreItemsList}>
                      <li>
                        <a href="tel:+420382766333">
                          <i className="icon-phone" />
                          +420 225 382 919
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-lowercase"
                          href="mailto:podpora@se.com"
                        >
                          <i className="icon-mail" />
                          podpora@se.com
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}
        <Row className={classes.bottom}>
          <Col xs={{ span: 12, order: 2 }} md={{ span: 4, order: 1 }}>
            <span className={classes.grey}>
              © {new Date().getFullYear()} {t('common:SCHNEIDER_COMPANY_NAME')}
            </span>
          </Col>
          <Col xs={{ span: 12, order: 1 }} md={{ span: 5, order: 2 }}>
            <a
              target="_blank"
              href="https://www.se.com/cz/cs/about-us/legal/terms-of-use.jsp"
              className={classes.grey}
            >
              {t('common:TERMS_OF_USE')}
            </a>
            <span className={classes.divider}></span>
            <a
              target="_blank"
              href="https://www.se.com/cz/cs/about-us/legal/data-privacy.jsp"
              className={classes.grey}
            >
              {t('common:PRIVACY_AND_COOKIE_POLICY')}
            </a>
          </Col>
          <Col
            xs={{ span: 12, order: 3 }}
            md={{ span: 3 }}
            className="text-center"
          >
            <span className={classes.grey}>
              Brought to life by{' '}
              <a target="_blank" href="https://bootiq.io">
                BOOT!Q
              </a>
            </span>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

Footer.defaultProps = {
  showInRow: false,
}

export default Footer
